import Vue from 'vue'
import VueRouter from 'vue-router'

// Layout
import LayoutMain from "../components/Layout/LayoutMain";

import Login from '../views/Login.vue'

import Employee from '../views/Employee/ListEmployee.vue'
import AddEmployee from '../views/Employee/AddEmployee.vue'
import EditEmployee from '../views/Employee/EditEmployee.vue'

import Addposition from '../views/position/Addposition.vue'
import Editposition from '../views/position/Editposition.vue'
import Listposition from '../views/position/Listposition.vue'

import ChangePassword from '../views/ChangePassword.vue'
import logout from '../views/logout.vue'

import AllLottoLock from '../views/Support/AllLottoLock.vue'
import ListLottoPrevious from '../views/Support/ListLottoPrevious.vue'
import ReportLogs from '../views/Support/ReportLogs.vue'
import test from '../views/Support/test.vue'
import Setting from '../views/Support/Setting.vue'

import checkusername from '../views/checkusername.vue'


Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/login',
            name: "Login",
            component: Login
        },
        {
            path: '/',
            redirect: '/login'
        },
        {
            path: "/v1",
            name: "LayoutMain",
            component: LayoutMain,
            children: [
                {
                    path: '/checkusername',
                    name: 'checkusername',
                    component: checkusername
                },

                {
                    path: '/employee',
                    name: 'Employee',
                    component: Employee
                },
                {
                    path: '/addemployee',
                    name: 'AddEmployee',
                    component: AddEmployee
                },
                {
                    path: '/editemployee',
                    name: 'EditEmployee',
                    component: EditEmployee
                },
                {
                    path: '/Addposition',
                    name: 'Addposition',
                    component: Addposition
                },
                {
                    path: '/Editposition',
                    name: 'Editposition',
                    component: Editposition
                },
                {
                    path: '/Listposition',
                    name: 'Listposition',
                    component: Listposition
                },
                {
                    path: '/changepassword',
                    name: 'ChangePassword',
                    component: ChangePassword
                },
                {
                    path: '/logout',
                    name: 'logout',
                    component: logout
                },
                {
                    path: '/all-lotto-lock',
                    name: 'AllLottoLock',
                    component:  AllLottoLock
                },
                {
                    path: '/list-lotto-previous',
                    name: 'ListLottoPrevious',
                    component:  ListLottoPrevious
                },
                {
                    path: '/reportlogs',
                    name: 'ReportLogs',
                    component:  ReportLogs
                },
                {
                    path: '/test',
                    name: 'test',
                    component:  test
                },
                {
                    path: '/setting',
                    name: 'Setting',
                    component:  Setting
                },

            ]
        }
    ]
})

export default router
