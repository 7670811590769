<template>
  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>แก้ไขข้อมูลผู้ดูแลระบบ</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <a href="#">หน้าแรก</a>
              </li>
              <li class="breadcrumb-item active">แก้ไขข้อมูลสมาชิก</li>
            </ol>
          </div>
        </div>
      </div>
      <!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-6">
            <div class="card">
              <!-- <div class="card-header"> -->
                <!-- <h3 class="card-title">DataTable with minimal features & hover style</h3> -->
              <!-- </div> -->
              <!-- /.card-header -->
              <div class="card-body">
                <form>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>ยูสเซอร์</label>
                        <input type="text" v-model="Username" class="form-control" readonly>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>ตำแหน่ง</label>
                        <input type="text" v-model="FirstlastName" class="form-control">
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>อีเมล์</label>
                        <input type="text" v-model="Email" class="form-control">
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>เบอร์โทรศัพท์</label>
                        <input type="text" v-model="Tel" class="form-control">
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-6">
                      <!-- select -->
                      <div class="form-group">
                        <label>สถานะการใช้งาน</label>
                        <select class="form-control">
                          <option value="1">เปิด</option>
                          <option value="0">ปิด</option>
                        </select>
                      </div>
                    </div>
                  </div>

                </form>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                <button type="submit" class="btn btn-secondary"><i class="far fa-save"></i> บันทึกข้อมูล</button> <a href="/Listposition" class="btn btn-danger"><i class="fas fa-reply"></i> กลับ</a> 
              </div>
            </div>
            <!-- /.card -->
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
    <div class="modal fade bd-confirm-modal-xl" id="ConfirmModal" tabindex="-1" role="dialog"
         aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">ยืนยันรหัสผ่าน</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!--            <p>กรุณาใส่หรัสผ่าน</p>-->
            <form>
              <div class="form-group">
                <label for="message-text" class="col-form-label">Password:</label>
                <input type="password" class="form-control" id="password-input" v-model="password" maxlength="20">
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">ปิด</button>
            <button type="button" @click.prevent="EditMember()" class="btn btn-primary">ตกลง</button>
          </div>
        </div>
      </div>
    </div>

  </div>
  <!-- /.content-wrapper -->
</template>

<script>
export default {
  name : "Addposition",
  data() {
    return {
      Username: "",
      FirstlastName : "",
      Email : "",
      Tel : ""
    }
  },
  created() {
    this.Permission()
  },
  mounted() {
    this.Username = this.$route.params.Username
    this.FirstlastName = this.$route.params.FirstlastName
    this.Email = this.$route.params.Email
    this.Tel = this.$route.params.Tel
  },
  methods : {
    async Permission() {}
  }
}
</script>

