<template>
  <router-view></router-view>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
<script>
import Nav from "./components/Layout/Nav";
import Sidebar from './components/Layout/Sidebar';
import Footer from './components/Layout/Footer';


// @CSS
require("../lib/plugins/fontawesome-free/css/all.min.css");
require("../lib/plugins/tempusdominus-bootstrap-4/css/tempusdominus-bootstrap-4.min.css");
require("../lib/plugins/icheck-bootstrap/icheck-bootstrap.min.css");
require("../lib/plugins/jqvmap/jqvmap.min.css");
require("../lib/css/adminlte.min.css");
require("../lib/plugins/overlayScrollbars/css/OverlayScrollbars.min.css");
require("../lib/plugins/daterangepicker/daterangepicker.css");
require("../lib/plugins/summernote/summernote-bs4.min.css");
require("../lib/css/style.css");
require("../lib/css/stylek.css");
require("../lib/css/responsive.css");



// @JS
require("../lib/plugins/bootstrap/js/bootstrap.bundle.min.js");
require("../lib/plugins/chart.js/Chart.min.js");
require("../lib/plugins/sparklines/sparkline.js");
require("../lib/plugins/jquery-knob/jquery.knob.min.js");
require("../lib/plugins/daterangepicker/daterangepicker.js");
require("../lib/plugins/summernote/summernote-bs4.min.js");
require("../lib/plugins/overlayScrollbars/js/jquery.overlayScrollbars.min.js");
require("../lib/js/adminlte.js");


// my-component.js
export default {
  components: { Nav,Sidebar,Footer},
    name: 'App',
    data () {
      return {}
    }
}



</script>
