<template>
  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>รายงาน Log ผู้ใช้งาน</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <a href="/Dashboard">หน้าแรก</a>
              </li>
              <li class="breadcrumb-item active">รายงาน Log ผู้ใช้งาน</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="row card-body">
                <div class="col-2 responsive-col-12">
                  <div class="row">
                    <div class="col">เริ่มวันที่ - ถึงวันที่</div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <date-picker
                        v-model="date1"
                        lang="en"
                        type="date"
                        range
                        style="width: 100%"
                        format="YYYY-MM-DD"
                      >
                      </date-picker>
                    </div>
                  </div>
                </div>
                <div
                  class="col-4 responsive-col-12 pl-0"
                  style="padding-top: 1.1rem !important"
                >
                  <div class="row">
                    <div class="col pr-0">
                      <button
                        class="btn btn btn-warning btn-rounded"
                        @click.prevent="Search()"
                      >
                        ค้นหา
                      </button>
                      &nbsp;
                      <button
                        class="btn btn bg-lotus btn-rounded"
                        @click.prevent="Inittoday()"
                      >
                        วันนี้
                      </button>
                      &nbsp;
                      <button
                        class="btn btn btn-secondary btn-rounded"
                        @click.prevent="Inityesterday()"
                      >
                        เมื่อวาน
                      </button>
                      &nbsp;
                      <button
                        class="btn btn bg-pink btn-rounded"
                        @click.prevent="Initweek()"
                      >
                        สัปดาห์
                      </button>
                      &nbsp;
                      <button
                        class="btn btn bg-purple btn-rounded"
                        @click.prevent="Initmonth()"
                      >
                        เดือน
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-sm-12">
            <div class="card card-primary card-tabs">
              <!-- pageAll -->
              <div
                id="custom-tabs-one-WithdrawSuccess1"
                class="tab-pane active"
              >
                <div class="card-body pt-2">
                  <section class="content">
                    <div class="container-fluid">
                      <div class="row">
                        <div class="col-12 pl-0" style="overflow-x: auto">
                          <table
                            id="TableDepositFail"
                            class="
                              table table-striped table-bordered table-sm
                            "
                          >
                            <thead class="bg-header">
                              <tr>
                                <th
                                  class="
                                    text-center
                                    font-weight-normal
                                    py-3
                                    p-0
                                  "
                                  v-for="header in tableHeader"
                                  :key="header"
                                >
                                  {{ header }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(Website, index) in ListWebsite"
                                :key="index"
                                class="bg-white text-center"
                              >
                                <td>{{ Website.logs_id }}</td>
                                <td>{{ Website.create_date | formateDateTime }}</td>
                                <td>{{ Website.logs_type }}</td>
                                <td>{{ Website.logs_user }}</td>
                                <td>{{ Website.logs_event }}</td>
                                <td>{{ Website.logs_detail }}</td>
                                <td>{{ Website.logs_table }}</td>
                                <td>{{ Website.logs_table_id }}</td>
                                <td>{{ Website.logs_api }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <!-- /.col -->
                      </div>
                      <!-- /.row -->
                    </div>
                    <!-- /.container-fluid -->
                  </section>
                </div>
                <!-- /.content -->
              </div>
              <!-- pageWaiting -->

              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- Show approve remove-->
    <!-- /.content -->
    <!-- /.content -->
  </div>
  <!-- /.content-wrapper -->
</template>

<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/zh-cn";
import main from "../../components/Layout/LayoutMain";

export default {
  name: "ReportLogs",
  components: {
    DatePicker,
  },
  data() {
    return {
      ListWebsite: [],
      tableHeader: [
        "ลำดับ",
        "วันที่สร้างรายการ",
        "Method",
        "ชื่อผู้ใช้งาน",
        "เหตุการณ์",
        "ข้อมูลเพิ่มเติม",
        "ชื่อ Table",
        "Id ใน Table",
        "Route API"
      ],
      Number_Lotto:null,
      search_number_lock:null,
      date1: [new Date(), new Date()],
      date_lock:null,
      password:null,
      SelectLotto:null,
      work_date:null,
      numberset:"",
      number_id:null,
      status:null,
      Lotto:{},
      current_time:"",
      current_date:"",
      close_time:""
    };
  },
  mounted() {
    this.Initweek();
  },

  methods: {
    Search() {
      this.InitLogs()
    },
    Inittoday() {
      this.date1 = [new Date(moment(Date.now() - 0 * 24 * 3600 * 1000)),new Date(moment(Date.now() - 0 * 24 * 3600 * 1000))]
      this.InitLogs()
    },
    Inityesterday() {
      this.date1 = [new Date(moment(Date.now() - 1 * 24 * 3600 * 1000).format("YYYY-MM-DD")),new Date(moment(Date.now() - 1 * 24 * 3600 * 1000).format("YYYY-MM-DD"))]
      this.InitLogs()
    },
    Initweek() {
      this.date1 = [new Date(moment().weekday(1).format("YYYY-MM-DD")),new Date(moment().weekday(7).format("YYYY-MM-DD"))]
      this.InitLogs()
    },
    Initmonth() {
      this.date1 = [new Date(moment().date(1).format("YYYY-MM-DD")),new Date(moment().date(31).format("YYYY-MM-DD"))]
      this.InitLogs()
    },
    InitLogs() {
      let Obj = {
        token: localStorage.getItem("Token"),
        request:{
          numberset:this.search_number_lock,
          start_date:(this.formatDate(this.date1[0]) == "Invalid date"? "" : this.formatDate(this.date1[0])),
          end_date:(this.formatDate(this.date1[1]) == "Invalid date"? "" : this.formatDate(this.date1[1]))
        }
      };
      this.axios
        .post(this.DomainBaseURL + "api/v1/get/get_logs", Obj)
        .then((response) => {
          this.ListWebsite = response.data.res;
        });
    },
    formatDate(value) {
      return moment(String(value)).format("YYYY-MM-DD");
    },
  },
  filters: {
    formateDateTime(value) {
      return moment(String(value)).format('YYYY-MM-DD HH:mm:ss')
    }
  },
};
</script>

<style>
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.bg-lotus {
  background-color: #00beb5 !important;
  color: white !important;
}
.bg-header {
  background-color: #353a40;
  color: white;
}
.bg-before-credit {
  background-color: #06bad5;
}
.bg-after-credit {
  background-color: #f7685b;
}
.bg-scb {
  background-color: #562684;
}
.bg-kbank {
  background-color: #2f963b;
}
.ml-3-custom {
  margin-left: 1rem;
}
.table th,
.table td {
  vertical-align: middle !important;
}
</style>
