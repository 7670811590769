<template>
  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>เพิ่มผู้ใช้งาน</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <a href="#">หน้าแรก</a>
              </li>
              <li class="breadcrumb-item active">เพิ่มผู้ใช้งาน</li>
            </ol>
          </div>
        </div>
      </div>
      <!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-6">
            <div class="card">
              <!-- <div class="card-header"> -->
              <!-- <h3 class="card-title">DataTable with minimal features & hover style</h3> -->
              <!-- </div> -->
              <!-- /.card-header -->
              <div class="card-body">
                <form>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>ยูสเซอร์</label>
                        <input
                          type="text"
                          v-model="Username"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>รหัสผ่าน</label>
                        <input
                          type="text"
                          v-model="Password"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>ชื่อผู้ใช้งาน</label>
                        <input
                          type="text"
                          v-model="FirstlastName"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>อีเมล์</label>
                        <input
                          type="text"
                          v-model="Email"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </div>

                </form>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                <button type="submit" class="btn btn-secondary mr-1" @click="Confirm_()">
                  <i class="far fa-save"></i> บันทึกข้อมูล
                </button>
                <a href="/Employee" class="btn btn-danger"
                  ><i class="fas fa-reply"></i> กลับ</a
                >
              </div>
            </div>
            <!-- /.card -->
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
    <div class="modal fade bd-confirm-modal-xl" id="ConfirmModal" tabindex="-1" role="dialog"
         aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">ยืนยันรหัสผ่าน</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!--            <p>กรุณาใส่หรัสผ่าน</p>-->
            <form>
              <div class="form-group">
                <label for="message-text" class="col-form-label">Password:</label>
                <input type="password" class="form-control" id="password-input" v-model="password" maxlength="20">
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">ปิด</button>
            <button type="button" @click.prevent="AddEmployee()" class="btn btn-primary">ตกลง</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /.content-wrapper -->
</template>

<script>
export default {
  name: "AddEmployee",
  data() {
    return {
      Username: "",
      password: "",
      Password: "",
      FirstlastName: "",
      Email: "",
    };
  },
  created() {
  },
  mounted() {},
  methods: {
    Confirm_() {
      $(".bd-confirm-modal-xl").modal({backdrop: "static"});
      $('.bd-confirm-modal-xl').modal('show')
    },
    async AddEmployee() {
      if (this.Username == "" || this.Password == "" || this.FirstlastName == "" || this.Email == "") {
        alert("กรุณาใส่ข้อมูลให้ครบถ้วน")
        $('.bd-confirm-modal-xl').modal('hide')
        this.password = "";
        return;
      }
      var password = this.Password.trim();
      if (password < 3) {
        alert("รหัสผ่านต้องมากกว่า 3 ตัวอักษร");
        $('.bd-confirm-modal-xl').modal('hide')
        this.password = "";
        return;
      }

      let Obj = {
        token: localStorage.getItem("Token"),
        Password: this.password,
        request: {
          Username: this.Username,
          Password: this.Password,
          PmsId: 2,
          Email: this.Email,
          Name: this.FirstlastName,
          IsActive: 1,
          create_by: localStorage.getItem("User"),
        },
      };

      this.axios
        .post(this.DomainBaseURL + "api/v1/employee/add_employee", Obj)
        .then((response) => {
          if (response.data.code == 0) {
            alert("บันทึกสำเร็จ");
            $('.bd-confirm-modal-xl').modal('hide')
            this.password = "";
            location.href = "/employee";
          } else if (response.data.code == 1000) {
            alert("ยูสเซอร์นี้มีผู้ใช้งานแล้ว")
            this.password = "";
            $('.bd-confirm-modal-xl').modal('hide')
            return;
          } else if (response.data.code == 1010) {
            alert("ยืนยันรหัสผ่านผิด กรุณาลองใหม่อีกครั้ง")
            this.password = "";
            return;
          }
        });
    },
  },
};
</script>

