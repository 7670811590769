import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import Vuelidate from 'vuelidate'
import GoogleAuth from 'vue-google-authenticator'
import ApexCharts from 'apexcharts'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


// import $ from 'jquery'


// app.use(VueSweetalert2);
Vue.use(Vuelidate)
Vue.prototype.DomainBaseURL = "https://api.laodls.com/" // localhost
// Vue.prototype.DomainBaseURL = "http://localhost:7777/" // localhost

Vue.prototype.logobanner = "http://laodls.com/img/Untitled-2-1.25ae7f7f.png" // localhost
Vue.prototype.headertitle = "ลาวพัฒนา รวมใจ" // localhost


Vue.prototype.axios = axios

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
