<template>
  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>รายชื่อผู้ใช้งาน</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <a href="/Dashboard">หน้าแรก</a>
              </li>
              <li class="breadcrumb-item active">รายชื่อผู้ใช้งาน</li>
            </ol>
          </div>
        </div>
      </div>
      <!-- /.container-fluid -->
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="row card-body">
                <div class="col-2 responsive-col-12">
                  <div class="row">
                    <div class="col">ค้นหาชื่อผู้ใช้งาน</div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <input
                        class="form-control form-control-sm"
                        type="text"
                        placeholder="ค้นหาชื่อผู้ใช้งาน"
                        v-model="search_username"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="col-4 responsive-col-12 pl-0"
                  style="padding-top: 1.1rem !important"
                >
                  <div class="row">
                    <div class="col pr-0">
                      <button
                        class="btn btn btn-warning btn-rounded"
                        @click="search_employee()"
                      >
                        ค้นหา
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">


              <!-- /.card-header -->
              <div class="card-body">

                <table class="table table-striped table-bordered table-sm">
                  <thead class="bg-header">
                  <tr>
                    <th class="text-center font-weight-normal py-3 p-0" v-for="header in tableHeader" :key="header">{{ header }}</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr class="bg-white text-center" v-for="(employee, index) in ListEmployee" :key="index">
                      <td>{{ employee.Username }}</td>
                      <td>{{ employee.name }}</td>
                      <td>{{ employee.email }}</td>
                      <td>{{ employee.StatusActive == 0 ? "ปิด" : "เปิด" }}</td>
                      <td>
                      
                        <button type='button' class='btn btn-secondary btn-sm' @click.prevent="EditEmployee(employee)"><i
                            class='fas fa-pencil-alt'></i> แก้ไข
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->

  </div>
  <!-- /.content-wrapper -->
</template>

<script>
import moment from 'moment';

$(document).ready(function () {
  function copyToClipboard() {
    window.clipboardData.setData('Text', textToPutOnClipboard);
  }
});


export default {
  name: "Member",
  data() {
    return {
      base_url: this.DomainBaseURL,
      ListEmployee: [],
      tableHeader: ["ยูสเซอร์", "ชื่อผู้ใช้งาน", "อีเมล์", "สถานะการใช้งาน", "จัดการ"],
      dataTable: null,
      token: localStorage.getItem("Token"),
      search_username: "",
      search_permission: "",

    }
  },
  mounted() {
    this.InitEmployee()
  },
  methods: {
    search_employee() {
      this.InitEmployee();
    },
    async InitEmployee() {
      const Obj = {
        "token": this.token,
        "request": {
          "search_admin": this.search_username,
          "id": "",
          "PmsId": "",
          "IsActive": "",
          " status": "",
          " Username": ""
        }
      }
      this.axios.post(this.base_url + "api/v1/employee/show_employee", Obj)
          .then((response) => {
            this.ListEmployee = response.data.res
            this.$nextTick(function () {
            })
          });
    },
    EditEmployee(employee) {
      this.$router.push({name: 'EditEmployee', params: employee})
    },
  },
  filters: {
    formatDate(value) {
      return moment(String(value)).format('YYYY-MM-DD HH:mm:ss')
    }
  }
};
</script>

