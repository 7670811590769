<template>
    <!-- Navbar -->
        <nav class="main-header navbar navbar-expand navbar-white navbar-light">
          <!-- Left navbar links -->
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link" data-widget="pushmenu" href="#" role="button"
                ><i class="fas fa-bars"></i
              ></a>
            </li>
          </ul>

          <!-- Right navbar links -->
          <ul class="navbar-nav ml-auto">
            <!-- Navbar Search -->
            

            <!-- Messages Dropdown Menu -->
            <li class="nav-item dropdown">
              <a class="nav-link" data-toggle="dropdown" href="#">
                <img
                      src="../../../lib/img/user1-128x128.jpg"
                      alt="User Avatar"
                      class="img-size-32 mr-3 img-circle"
                    />
              </a>
              <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                <a href="#" class="dropdown-item dropdown-footer">
                  <!-- Message Start -->
                  <div class="media">
                    <div class="media-body">
                      <p class="text-sm">แก้ไขข้อมูลส่วนตัว</p>
                    </div>
                  </div>
                  <!-- Message End -->
                </a>
                <div class="dropdown-divider"></div>
                <a href="#" class="dropdown-item dropdown-footer"
                  ><div class="media">
                    <div class="media-body">
                      <p class="text-sm">ออกจากระบบ</p>
                    </div>
                  </div></a
                >
              </div>
            </li>
          </ul>
        </nav>
        <!-- /.navbar -->
</template>
<script>
// my-component.js
export default {
    name: 'Nav',
    data () {
      return {}
    }
}
</script>