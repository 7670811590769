<template>
  <!-- Content Wrapper. Contains page content -->
  <!-- /.content-wrapper -->
  <div></div>
</template>

<script>
export default {
  name: "DepositFail",
  data() {
    return {};
  },
  created() {},
  mounted() {
    this.Initlogout();
  },
  methods: {
    Initlogout() {
      let Obj = {
        token: localStorage.getItem("Token"),
        request : {
          admin_id: localStorage.getItem("User_Id"),
          create_by: localStorage.getItem("User"),
        }
      };
      this.axios
        .post(this.DomainBaseURL + "api/v1/employee/logout", Obj)
        .then((response) => {

        });
      localStorage.removeItem("Token");
      localStorage.removeItem("token");
      this.$router.push({ name: "Login" });
    },
  },
  filters: {
    formatDate(value) {
      return moment(String(value)).format("YYYY-MM-DD HH:mm:ss");
    },
  },
};
</script>
