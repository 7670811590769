<template>
    <footer class="main-footer text-left">
          2020 © Kiosk by OMS+
        </footer>
</template>
<script>
// my-component.js
export default {
    name: 'Footer',
    data () {
      return {}
    }
}
</script>