<template>
  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>ตารางแผนก</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <a href="/Dashboard">หน้าแรก</a>
              </li>
              <li class="breadcrumb-item active">จัดการแผนก</li>
            </ol>
          </div>
        </div>
      </div>
      <!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">


              <!-- /.card-header -->
              <div class="card-body">

                <h3>รายชื่อแผนก</h3>
                <hr/>
                <table id="TableEmployee" class="table table-bordered table-hover dataTable dtr-inline">
                  <thead>
                  <tr>
                    <th v-for="header in tableHeader" :key="header">{{ header }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(data, index) in DataList" :key="index">
                    <td>{{ data.NamePermission }}</td>
                    <td>{{ data.level }}</td>
                    <td>{{ data.StatusActive == 0 ? "ปิด" : "เปิด" }}</td>
                    <td>
                      <button type='button' class='btn btn-secondary btn-sm' @click.prevent="Editposition(data)"><i
                          class='fas fa-pencil-alt'></i> แก้ไข
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
    <div class="modal fade bd-confirm-modal-xl" id="ConfirmModal" tabindex="-1" role="dialog"
         aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">แก้ไขรายชื่อแผนก</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!--            <p>กรุณาใส่หรัสผ่าน</p>-->
            <form>
              <label for="message-text" class="col-form-label">ชื่อตำแหน่ง : </label> <input type="text"
                                                                                             v-model="update_permission_name"><br/>

              <label for="message-text" class="col-form-label">ระดับชั้น : </label> <input type="text"
                                                                                           v-model="update_level"><br/>

              <label for="message-text" class="col-form-label">เรียงลำดับ : </label> <input type="text"
                                                                                            v-model="update_rank"><br/>
              <hr/>

              <div v-for="(data, index) in MenuList" :key="index" class="form-group">
                <input
                    type="checkbox" v-model="data.is_active"> : {{ index + 1 }} <label for="message-text"
                                                                                       class="col-form-label">
                {{ data.menu_name }}</label>

                <div v-for="(data_detail,index2) in data.detail"> l- <input
                    type="checkbox" v-model="data_detail.is_active"> :
                  {{ index + 1 }}.{{ index2 + 1 }}
                  <label for="message-text" class="col-form-label"> {{ data_detail.menu_name }} </label>
                </div>
                <hr>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">ปิด</button>
            <button type="button" @click.prevent="Editpermission()" class="btn btn-primary">ตกลง</button>
          </div>
        </div>
      </div>
    </div>

  </div>

  <!-- /.content-wrapper -->
</template>

<script>
import moment from 'moment';

export default {
  name: "position",
  data() {
    return {
      base_url: this.DomainBaseURL,
      DataList: [],
      MenuList: [],
      tableHeader: ["ชื่อตำแหน่ง", "ระดับชั้น", "สถานะ", "จัดการ"],
      dataTable: null,
      token: localStorage.getItem("Token"),
      update_permission_name: "",
      update_level: "",
      update_rank: "",
      permission_id: 0,
    }
  },
  mounted() {
    this.InitEmployee()
    this.dataTable = $('#TableEmployee').DataTable();
    this.dataTable.destroy();
  },
  methods: {
    async Init(id = 0) {
      const Obj = {
        "token": this.token,
        "request": {
          "id": id,
          "status": "all",
          "Username": "",
          "limit": ""
        }
      }
      this.axios.post(this.base_url + "api/v2/permission/show_menu", Obj)
          .then((response) => {
            this.MenuList = response.data.res

            this.$nextTick(function () {

            })
          });
    },

    async InitEmployee() {
      const Obj = {
        "token": this.token,
        "request": {
          "id": "",
          "status": "all",
          "Username": "",
          "limit": ""
        }
      }
      this.axios.post(this.base_url + "api/v2/permission/show_permission", Obj)
          .then((response) => {
            this.DataList = response.data.res
            this.$nextTick(function () {
              // $('#TableEmployee').DataTable({
              //   "paging" : true,
              //   "bInfo" : false,
              //   "paginate": {
              //   "next": "ถัดไป"
              // }})
            })
          });
    },
    Editposition(data) {
      this.Init(data.PermissionId)

      $(".bd-confirm-modal-xl").modal({backdrop: "static"});
      $('.bd-confirm-modal-xl').modal('show')
      this.update_permission_name = data.NamePermission
      this.update_level = data.level
      this.update_rank = data.rank
      this.permission_id = data.PermissionId

    },
    Editpermission() {
      $(".bd-confirm-modal-xl").modal({backdrop: "static"});
      $('.bd-confirm-modal-xl').modal('hide')
      let update_permission_name = this.update_permission_name
      let update_level = this.update_level
      let update_rank = this.update_rank

      if (!confirm("ยืนยันการแก้ไข")) {
        return
      }
      const Obj = {
        "token": this.token,
        "request": {
          "update_permission_name": update_permission_name,
          "update_level": update_level,
          "update_rank": update_rank,
          "menu_list": this.MenuList,
          "permission_id": this.permission_id
        }
      }
      this.axios.post(this.base_url + "api/v2/permission/update_permission", Obj)
          .then((response) => {

            if (response.data.code == 0) {
              alert("แก้ไขสำเร็จ");
              location.href = '/Listposition';
            } else {
              alert("แก้ไขไม่สำเร็จ");
            }
            return
          });
    },
    destroyed() {
      this.dataTable.destroy();
    }
  },
  filters: {
    formatDate(value) {
      return moment(String(value)).format('YYYY-MM-DD HH:mm:ss')
    }
  }
};
</script>

