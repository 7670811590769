<template>
  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>CheckUsername</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li class="breadcrumb-item active">CheckUsername</li>
            </ol>
          </div>
        </div>
      </div>
      <!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <!-- start -->
    <div class="content">
      <div class="container-fluid">
        <div class="">
          <div class="">
            <div class="">
              <div class="row">
                <div class="col-12">
                  <div class="">
                    <!-- Small boxes (Stat box) -->
                    <div class="row">
                      <div class="style_cb card-body">
                        <div
                        
                        >
                          <div
                        
                          >
                            <!-- /.card-header -->
                            <div class="row">
                             
                              <div class="col-2 mt-2 lb_sr">
                                ชื่อผู้ใช้งาน
                              </div>
                         
                            </div>
                        <div class="row">
                 
                            
                              <div class="col-2 mt-1 mb-3">
                                <input
                                    type="text"
                                    name="search"
                                    placeholder="ชื่อผู้ใช้งาน"
                                    v-model="search_username"
                                    class="form-control form-control-sm"
                                />
                              </div>
                      
                              <div class="col-1">
                                <button
                                    class="btn btn-dark btn-rounded shadow"
                                    @click.prevent="searchusername()"
                                >
                                  ค้นหา
                                </button>
                              </div>
                              
                            </div>
                          

                                
                              
                             
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
       <div v-if=" username.length > 0">
           <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3">

            <!-- Profile Image -->

            <div class="card card-primary card-outline">
              <div class="card-body box-profile">
                <div class="text-center">
                  <img class="profile-user-img img-fluid img-circle" :src="PictureLine" alt="User profile picture">
                </div> 

                  




                <h3 class="profile-username text-center">{{Firstname }}</h3>

                <p class="text-muted text-center">Username : {{UsernameRef }}<br>Tel :{{Tel}} </p>

                <ul class="list-group list-group-unbordered mb-3">
                  <li class="list-group-item">
                    <b>สมัครทางช่องทาง</b><br> <a class="float-center">{{channal }}</a>
                  </li>
                  <li class="list-group-item">
                    <b>วันที่สมัคร </b> <br><a class="float-center">{{DateRegister  | formatDate }}</a>
                  </li> 
                  <li class="list-group-item">
                    <b>วันที่เข้าใช้งานล่าสุด</b> <br><a class="float-center">{{LastLogin | formatDate }}</a>
                  </li>
                   <li class="list-group-item">
                    <b>upLine :</b> <br><a class="float-center">{{upline }}</a>
                  </li>
                </ul>

                <a href="#" class="btn btn-primary btn-block"><b>Follow</b></a>
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->

            <!-- About Me Box -->
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-header align-items-flex-center">About Me</h3>
              </div>
              <!-- /.card-header -->
              <div class="card-body">
                <strong><i class="fas fa-money-bill-alt"></i> ยอดฝากรวมทั้งหมด </strong>

                <p class="text-muted">
                  มีรายการฝากทั้งหมด {{ListDeposit.length}} รายการ
                </p>
                <p class="text-muted">
                  ยอดฝากทั้งหมด 
                </p>
                <p class="text-muted">{{total_deposit}} บาท</p>

                <hr>

                <strong><i class="fas fa-money-check"></i> ยอดถอนรวมทั้งหมด </strong>

                <p class="text-muted">มีรายการถอนทั้งหมด {{Listwithdraw.length}} รายการ</p>
                <p class="text-muted">ถอดถอนทั้งหมด</p>
                <p class="text-muted">{{total_withdraw}} บาท</p>
                <hr>

                <strong><i class="fas fa-money-check"></i> คงเหลือ </strong>

                <p class="text-muted"> {{num_format((total_deposit-total_withdraw),2)}} </p>
            
                <hr>

           
              
             
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
          <!-- /.col -->
          <div class="col-md-9">
            <div class="card">
              <div class="card-header p-2">
                <ul class="nav nav-pills">
                  <li class="nav-item"><a class="nav-link active" href="#activity" data-toggle="tab">การฝาก</a></li>
                  <li class="nav-item"><a class="nav-link" href="#timeline" data-toggle="tab">การถอน</a></li>
                  <!-- <li class="nav-item"><a class="nav-link" href="#settings" data-toggle="tab">Settings</a></li> -->
                </ul>
              </div><!-- /.card-header -->
              <div class="card-body">
                <div class="tab-content">
                  <div class="tab-pane active" id="activity">
                    <!-- Post -->
                    <div class="row">
                        <div class="col-12 pl-0" style="overflow-x:auto;">
                          <table
                            id="TableDepositFail"
                            class="table table-striped table-bordered table-sm"
                          >
                            <thead class="header">
                              <tr>
                                <th
                                  class="text-center font-weight-normal py-3 p-0"
                                  v-for="header in tableHeader"
                                  :key="header"
                                >
                                  {{ header }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(deposit, index) in ListDeposit"
                                :key="index"
                                class="bg-white text-center"
                              >
                                <td>{{ index + 1 }}</td>
                                <td>
                                 {{ deposit.UsernameRef }}

                                  
                                </td>

                                <td>{{ deposit.Namebank }}<br>{{ deposit.AccountBankCustomer }}</td>
                                <td>{{ deposit.topupamonut }}</td>
                                <td>
                                  {{ deposit.StatusTopup  }}
                                </td>
                                <td>{{ deposit.TypePromotion}}</td>
                               
                                <td>{{ deposit.DateTopup | formatDate }}</td>
                                <td>{{ deposit.DateTopup | formatDate }}</td>
                                <td>{{ deposit.TopupBy }}</td>
                                 <td>{{ deposit.IPaddress  }}</td>
                                    <tr>
                                      <td colspan="3">รวม</td>
                                      <td>{{ num_format(this.total_deposit) }}</td>
                                    
                                    </tr>
                      
                                
                              
                           
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <!-- /.col -->
                      </div>
                    <!-- /.post -->

                    <!-- Post -->
                  
                    <!-- /.post -->

                    <!-- Post -->
                  
                    <!-- /.post -->
                  </div>
                  <!-- /.tab-pane -->
                  <div class="tab-pane" id="timeline">
                    <!-- The timeline -->
                    <div class="timeline timeline-inverse">
                      <!-- timeline time label -->
                       <div class="row">
                        <div class="col-12 pl-0" style="overflow-x:auto;">
                          <table
                            id="TableDepositFail"
                            class="table table-striped table-bordered table-sm"
                          >
                            <thead class="header">
                              <tr>
                                <th
                                  class="text-center font-weight-normal py-3 p-0"
                                  v-for="header in tableHeader2"
                                  :key="header"
                                >
                                  {{ header }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(deposit, index) in Listwithdraw"
                                :key="index"
                                class="bg-white text-center"
                              >
                                <td>{{ index + 1 }}</td>
                                <td>
                                 {{ deposit.UsernameRef }}

                                  
                                </td>

                                <td>{{ deposit.Namebank }}<br>{{ deposit.AccountNumber }}</td>
                                <td>{{ deposit.withdrawamonut }}</td>
                                <td>
                                  {{ deposit.StatusProcess  }}
                                </td>
                                <td>{{ deposit.CreateDate | formatDate }}</td>
                                <td>{{ deposit.DateSendWithdraw | formatDate }}</td>
                                <td>{{ deposit.WithDrawBy }}</td>
                                 <td>{{ deposit.IPaddress }}</td>
                                    <tr>
                                      <td colspan="3">รวม</td>
                                      <td>{{ num_format(this.total_withdraw) }}</td>
                                    
                                    </tr>
                      
                                
                              
                           
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <!-- /.col -->
                      </div>
                      <!-- /.timeline-label -->
                      <!-- timeline item -->
                    
                      <!-- END timeline item -->
                      <!-- timeline item -->
                    
                      <!-- END timeline item -->
                      <!-- timeline item -->
                     
                      <!-- END timeline item -->
                      <!-- timeline time label -->
                    
                      <!-- /.timeline-label -->
                      <!-- timeline item -->
                     
                      <!-- END timeline item -->
                    
                    </div>
                  </div>
                  <!-- /.tab-pane -->

                  <div class="tab-pane" id="settings">
                    <form class="form-horizontal">
                      <div class="form-group row">
                        <label for="inputName" class="col-sm-2 col-form-label">Name</label>
                        <div class="col-sm-10">
                          <input type="email" class="form-control" id="inputName" placeholder="Name">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="inputEmail" class="col-sm-2 col-form-label">Email</label>
                        <div class="col-sm-10">
                          <input type="email" class="form-control" id="inputEmail" placeholder="Email">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="inputName2" class="col-sm-2 col-form-label">Name</label>
                        <div class="col-sm-10">
                          <input type="text" class="form-control" id="inputName2" placeholder="Name">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="inputExperience" class="col-sm-2 col-form-label">Experience</label>
                        <div class="col-sm-10">
                          <textarea class="form-control" id="inputExperience" placeholder="Experience"></textarea>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="inputSkills" class="col-sm-2 col-form-label">Skills</label>
                        <div class="col-sm-10">
                          <input type="text" class="form-control" id="inputSkills" placeholder="Skills">
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="offset-sm-2 col-sm-10">
                          <div class="checkbox">
                            <label>
                              <input type="checkbox"> I agree to the <a href="#">terms and conditions</a>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="offset-sm-2 col-sm-10">
                          <button type="submit" class="btn btn-danger">Submit</button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <!-- /.tab-pane -->
                </div>
                <!-- /.tab-content -->
              </div><!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div><!-- /.container-fluid -->
    </section>
       </div>

        </div>
            <!-- card -->
          </div>
          <!-- card body -->
        </div>
        <!-- card -->
      </div>
      <!-- container-fluid -->
    
    <!-- end -->
    <!-- content -->
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/zh-cn";
import moment from "moment";

export default {
  name: "Promotion",
  components: {
    DatePicker,
  },
  data() {
    return {
      tableHeader: ["#", "ชื่อผู้ใช้งาน", "เลขบัญชี", "จำนวนเงิน" , "สถานะ" ,"โปรโมชั่น", "CreateDate", "Datetopup" ,"เติมโดย","IP Address"],
      tableHeader2: ["#", "ชื่อผู้ใช้งาน", "เลขบัญชี", "จำนวนเงิน" , "สถานะ" , "CreateDate", "วันที่ถอน" ,"ถอนโดย","IP Address"],
      date1: "",
      date2: "",
      Codebank: "0",
      username :this.UsernameRef ,
      BankbotId:"0",
      username :[],
      search: "",
      bankauto:[],
      search_botbank :"all",
      search_username: "",
      Listbank:[],
      ListData:[],
      Bank:[],
      usernametopup:[],
    };
  },
  created() {
   
  },
  mounted() {
   // this.GetBank();
    // this.InitReport();
    // this.Inittopup();
  },
  methods: {
    
  
    searchusername() {
      this.Inittopup();
      this.InitReport();
      this.Initwithdraw() ;
  
    },
    InitReport() {
      // 1=succes,0=fail
      let Obj = {
        "token": localStorage.getItem("Token"),
        "request": {
          "search_username": this.search_username,
       
        }
      }
      this.axios.post(this.DomainBaseURL + 'api/v3/checkusername/checkusername', Obj).then((response) => {
           this.username = response.data
        this.$nextTick(function () {

            this.UsernameRef = this.username[0].UsernameRef
             this.Firstname = this.username[0].Firstname
              this.Tel = this.username[0].Tel
               this.Codebank = this.username[0].Codebank
               this.PictureLine = this.username[0].PictureLine
              this.DateRegister = this.username[0].DateRegister
               this.LastLogin = this.username[0].LastLogin
               this.channal = this.username[0].Channel
               this.upline = this.username[0].Upline
          

            // this.Firstname = this.username[0].Firstname,
            // this.Tel = this.username[0].Tel
           


          // $('#TableDepositFail').DataTable({
          //   "paging": true,
          //   "bInfo": false,
          // })
        })

      
      });

  
      
    },
    Inittopup() {
      // 1=succes,0=fail
      let Obj = {
        "token": localStorage.getItem("Token"),
        "request": {
          "search_username": this.search_username,
       
        }
       
      }
     
      this.axios.post(this.DomainBaseURL + 'api/v3/checkusername/topupusername', Obj).then((response) => {
           this.ListDeposit = response.data
            
        this.$nextTick(function () {
           

        

            // this.Firstname = this.username[0].Firstname,
            // this.Tel = this.username[0].Tel
            console.log( 'this', this.ListDeposit )


     
        })

      
      });

  
      
    },
     Initwithdraw() {
      // 1=succes,0=fail
      let Obj = {
        "token": localStorage.getItem("Token"),
        "request": {
          "search_username": this.search_username,
       
        }
       
      }
     
      this.axios.post(this.DomainBaseURL + 'api/v3/checkusername/withdrawusername', Obj).then((response) => {
           this.Listwithdraw = response.data
            
        this.$nextTick(function () {

        

            // this.Firstname = this.username[0].Firstname,
            // this.Tel = this.username[0].Tel
            console.log( 'this', this.Listwithdraw )


     
        })

      
      });

  
      
    },
   
    num_format(inp, toFixed = 0) {
      try {
        toFixed = toFixed || 0;
        if (toFixed == 99) {
          toFixed = 0;
        }
        if (inp == '' || inp == undefined) {
          inp = '0';
        }
        inp += '';
        var rt = parseFloat(inp).toFixed(toFixed).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      } catch (err) {
        var rt = '';
      }
      return rt;
    },
  },
  filters: {
    formatDate(value) {
      return moment(String(value)).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  computed: {
       total_deposit: function () {
      let sum1 = 0;
      for (let i = 0; i < this.ListDeposit.length; i++) {
        sum1 += Number(this.ListDeposit[i].topupamonut);
 
      }
  
      return sum1
    },
     total_withdraw: function () {
      let sum = 0;
    
      for (let i = 0; i < this.Listwithdraw.length; i++) {
         sum += (parseFloat(this.Listwithdraw[i].withdrawamonut));
        
      }
     
      return sum
    },

  }
};
</script>

<style></style>
