<template>
  <div class="wrapper">
    <!-- Navbar -->
    <nav class="main-header navbar navbar-expand navbar-white navbar-light">
      <link
        rel="stylesheet"
        href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"
      />
      <!-- Left navbar links -->
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" data-widget="pushmenu" href="#" role="button">
            <i class="fas fa-bars"></i>
          </a>
        </li>
        <!-- Notification -->
        <div>
          <!-- <router-link to="/notifications" class="btn btn-secondary" tag="button">ลูกค้าถอนผิดปกติ</router-link>
                      <router-link to="/withdrawfail" class="btn btn-secondary" tag="button">ถอนเงินไม่สำเร็จ</router-link>
                      <router-link to="/depositduplicate" class="btn btn-secondary" tag="button">ฝากบัญชีซ้ำ</router-link> -->

          <!-- <button type="button" class="btn btn-secondary" href="/withdrawfail" >
                        ถอนเงินไม่สำเร็จ <span class="badge pulsate">1</span>
                        &nbsp; &nbsp; &nbsp; &nbsp;
                      </button>
                       <button type="button" class="btn btn-secondary" to="/notifications"  href="/depositduplicate" >
                        ฝากบัญชีซ้ำ <span class="badge pulsate">1</span>
                      </button> -->
        </div>
      </ul>

      <!-- Right navbar links -->
      <ul class="navbar-nav ml-auto">
        <!-- Navbar Search -->

        <!-- Messages Dropdown Menu -->
        <li class="nav-item dropdown">
          <a class="" data-toggle="" href="#">
            <img
              src="https://biogaming1.com/wallet/static/img/Biogaming1_logo@2x.b55a608.png"
              class="img-size-32 "
            />
          </a>
          <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
            <a href="#" class="dropdown-item dropdown-footer">
              <!-- Message Start -->
              <div class="media">
                <div class="media-body">
                  <p class="text-sm">แก้ไขข้อมูลส่วนตัว</p>
                </div>
              </div>
              <!-- Message End -->
            </a>
            <div class="dropdown-divider"></div>
            <a href="#" class="dropdown-item dropdown-footer">
              <div class="media">
                <div class="media-body">
                  <p class="text-sm">ออกจากระบบ</p>
                </div>
              </div>
            </a>
          </div>
        </li>
      </ul>
    </nav>
    <!-- /.navbar -->

    <!-- Main Sidebar Container -->
    <aside class="main-sidebar sidebar-dark-primary elevation-4">
      <a href="/dashboardmain" class="brand-link">
        <img
          :src="logobanner"
          class="w-50"
        />
        <span class="brand-text font-weight-light">{{ headertitle }}</span>
      </a>
      <!-- Sidebar -->
      <div class="sidebar">
        <!-- Sidebar user panel (optional) -->
        <div class="user-panel mt-3 pb-3 mb-3 d-flex">
          <div class="info">
            <a class="d-block"> ชื่อผู้ใช้งาน : {{ mydetail.Username }}</a>
            <a class="d-block"> ระดับชั้น : {{ mydetail.PmsName }}</a>
          </div>
        </div>
        <!-- Sidebar Menu -->
        <nav class="mt-2 text-left">
          <ul
            class="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <div>
              <li v-for="(header, index) in listmenu" class="nav-item">
                <a :href="header.link" class="nav-link">
                  <!--                    <i class="nav-icon fas fa-chart-pie"></i>-->
                  <!--                    <span v-show="header.showNoti" class="right badge badge-danger">{{ NotiDFail }}</span>-->
                  <p>
                    {{ index + 1 }}.){{ header.menu_name }}
                    <i
                      v-show="header.detail.length > 0"
                      class="right fas fa-angle-left"
                    ></i>

                    <!-- notification -->
                    &nbsp;&nbsp;
                    <!-- <div class="badge pulsate">50</div> -->
                  </p>
                </a>

                <ul v-if="header.detail.length > 0" class="nav nav-treeview">
                  <li
                    v-for="(details, index2) in header.detail"
                    v-show="header.isVisible"
                    class="nav-item"
                  >
                    <a :href="details.link" class="nav-link">
                      <!-- <i class="far fa-circle nav-icon"></i> -->
                      <span
                        v-show="header.showNoti2"
                        class="right badge badge-danger"
                        >{{ NotiDFail }}</span
                      >
                      <p>
                        {{ index + 1 }}.{{ index2 + 1 }}){{ details.menu_name }}
                      </p>
                    </a>
                  </li>
                </ul>
              </li>
            </div>
          </ul>
        </nav>
        <!-- /.sidebar-menu -->
      </div>
      <!-- /.sidebar -->
    </aside>
    <!-- End Main Sidebar Container -->

    <!-- Content -->
    <router-view />
    <!-- End Content -->

    <!-- Footer -->
    <footer class="main-footer text-left">2021 © BO by WIN.GAME+</footer>
    <!-- End Footer -->

    <!-- Control Sidebar -->
    <aside class="control-sidebar control-sidebar-dark">
      <!-- Control sidebar content goes here -->
    </aside>
    <!-- /.control-sidebar -->
  </div>
  <!-- ./wrapper -->
</template>

<script>
import Nav from "./Nav";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { BBadge } from "bootstrap-vue";
import { BadgePlugin } from "bootstrap-vue";
// Vue.use(BadgePlugin)
// Vue.component('b-badge', BBadge)

export default {
  components: { Nav, Sidebar, Footer },
  data() {
    return {
      listmenu: [
        {
          menu_id: 10,
          menu_name: "ผลหวยล็อคทั้งหมด",
          menu_name_en: "ผลหวยล็อคทั้งหมด",
          menu_name_th: "ผลหวยล็อคทั้งหมด",
          rank: 101,
          level: 1,
          upline: 0,
          link: "/all-lotto-lock",
          isVisible: 1,
          isActive: 1,
          icon: "",
          is_admin: 0,
          detail: [],
        },
        {
          menu_id: 123,
          menu_name: "ผลหวยย้อนหลัง",
          menu_name_en: "ผลหวยย้อนหลัง",
          menu_name_th: "ผลหวยย้อนหลัง",
          rank: 136,
          level: 1,
          upline: 0,
          link: "/list-lotto-previous",
          isVisible: 1,
          isActive: 1,
          icon: "",
          is_admin: 0,
          detail: [],
        },
        {
          menu_id: 123,
          menu_name: "รายงาน Log ผู้ใช้งาน",
          menu_name_en: "รายงาน Log ผู้ใช้งาน",
          menu_name_th: "รายงาน Log ผู้ใช้งาน",
          rank: 136,
          level: 1,
          upline: 0,
          link: "/reportlogs",
          isVisible: 1,
          isActive: 1,
          icon: "",
          is_admin: 0,
          detail: [],
        },
        {
          menu_id: 334,
          menu_name: "ตั้งค่าหวย",
          menu_name_en: "ตั้งค่าหวย",
          menu_name_th: "ตั้งค่าหวย",
          rank: 131,
          level: 1,
          upline: 0,
          link: "/setting",
          isVisible: 1,
          isActive: 1,
          icon: "",
          is_admin: 0,
          detail: [],
        },
        {
          menu_id: 300,
          menu_name: "จัดการผู้ใช้งาน",
          menu_name_en: "จัดการผู้ใช้งาน",
          menu_name_th: "จัดการผู้ใช้งาน",
          rank: 301,
          level: 1,
          upline: 0,
          link: "",
          isVisible: 1,
          isActive: 1,
          icon: "",
          is_admin: 0,
          detail: [
            {
              menu_id: 311,
              menu_name: "รายชื่อผู้ใช้งาน",
              menu_name_en: "รายชื่อผู้ใช้งาน",
              menu_name_th: "รายชื่อผู้ใช้งาน",
              rank: 30101,
              level: 2,
              upline: 300,
              link: "/employee",
              isVisible: 1,
              isActive: 1,
              icon: "",
              is_admin: 0,
            },
            {
              menu_id: 312,
              menu_name: "เพิ่มผู้ใช้งาน",
              menu_name_en: "เพิ่มผู้ใช้งาน",
              menu_name_th: "เพิ่มผู้ใช้งาน",
              rank: 30102,
              level: 2,
              upline: 300,
              link: "/addemployee",
              isVisible: 1,
              isActive: 1,
              icon: "",
              is_admin: 0,
            },
          ],
        },
        {
          menu_id: 170,
          menu_name: "เปลี่ยนรหัสผ่าน",
          menu_name_en: "เปลี่ยนรหัสผ่าน",
          menu_name_th: "เปลี่ยนรหัสผ่าน",
          rank: 114,
          level: 1,
          upline: 0,
          link: "/changepassword",
          isVisible: 1,
          isActive: 1,
          icon: "",
          is_admin: 0,
          detail: [],
        },

        {
          menu_id: 400,
          menu_name: "ออกจากระบบ",
          menu_name_en: "ออกจากระบบ",
          menu_name_th: "ออกจากระบบ",
          rank: 117,
          level: 1,
          upline: 0,
          link: "/logout",
          isVisible: 1,
          isActive: 1,
          icon: "",
          is_admin: 0,
          detail: [],
        },
      ],
      mydetail: {},
      NotiWFail: 0,
      NotiDFail: 0,
      base_url: this.DomainBaseURL,
    };
  },
  mounted() {
    this.menu();
  },
  methods: {
    async menu() {
      let Obj = {
        token: localStorage.getItem("Token"),
      };
      this.axios
        .post(this.base_url + "api/v1/employee/checktoken", Obj)
        .then((response) => {

          this.mydetail = response.data.res;
          console.log(response.data);
          if (response.data.code == 9000) {
            localStorage.removeItem("Token");
            localStorage.removeItem("User");
            localStorage.removeItem("token");
            this.$router.push({ name: "Login" });
          }
          if (response.data.code == 0) {
            localStorage.setItem("User",this.mydetail.Username);
            localStorage.setItem("User_Id",this.mydetail.AdminId);
          }
        });
    },
  },
};

const counter = 0;
</script>

<style>
.badge {
  background-color: #ff6f61;
  color: #fff;
  border-radius: 50%;
  padding: 2px 10px;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
}
.pulsate::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: pulse 1s ease infinite;
  border: 4px double #ff6f61;
}

.pulsate {
  position: relative;
  display: inline-block;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  60% {
    transform: scale(1.3);
    opacity: 0.4;
    filter: blur(1px);
  }
  100% {
    transform: scale(1.4);
    opacity: 0;
    filter: blur(2px);
  }
}

.nav-bottom .nav .dropdown li a .badge {
  position: absolute;
  right: 8px;
  top: 13px;
  padding: 3px 7px;
  font-size: 10px;
}
</style>
