<template>
  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>แก้ไขข้อมูลผู้ใช้งาน</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <a href="#">หน้าแรก</a>
              </li>
              <li class="breadcrumb-item active">แก้ไขข้อมูลผู้ใช้งาน</li>
            </ol>
          </div>
        </div>
      </div>
      <!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-6">
            <div class="card">
              <!-- <div class="card-header"> -->
              <!-- <h3 class="card-title">DataTable with minimal features & hover style</h3> -->
              <!-- </div> -->
              <!-- /.card-header -->
              <div class="card-body">
                <form>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>ยูสเซอร์</label>
                        <input type="text" v-model="Username" class="form-control" readonly>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>ชื่อ นามสกุล</label>
                        <input type="text" v-model="FirstlastName" class="form-control">
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>อีเมล์</label>
                        <input type="text" v-model="Email" class="form-control">
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <!-- select -->
                      <div class="form-group">
                        <label>สถานะการใช้งาน</label>
                        <select v-model="IsActive" class="form-control">
                          <option value="1">เปิด</option>
                          <option value="0">ปิด</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                <button type="submit" @click.prevent="Confirm_()" class="btn btn-secondary mr-1"><i
                    class="far fa-save"></i> บันทึกข้อมูล
                </button>
                <a href="/Employee" class="btn btn-danger"><i class="fas fa-reply"></i> กลับ</a>
              </div>
            </div>
            <!-- /.card -->
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
    <div class="modal fade bd-confirm-modal-xl" id="ConfirmModal" tabindex="-1" role="dialog"
         aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">ยืนยันรหัสผ่าน</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!--            <p>กรุณาใส่หรัสผ่าน</p>-->
            <form>
              <div class="form-group">
                <label for="message-text" class="col-form-label">Password:</label>
                <input type="password" class="form-control" id="password-input" v-model="password" maxlength="20">
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">ปิด</button>
            <button type="button" @click.prevent="EditEmployee()" class="btn btn-primary">ตกลง</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /.content-wrapper -->
</template>

<script>
export default {
  name: "EditEmployee",
  data() {
    return {
      base_url: this.DomainBaseURL,
      EmployeeId: 0,
      Username: "",
      FirstlastName: "",
      Email: "",
      Password: "",
      Tel: "",
      StatusActive: 0,
      api_password:"",
      api_username:"",
      my_password: "",
      permission: 0,
      listpermission: [],
      token: localStorage.getItem("Token"),
      PmsId:0,
      IsActive:0,
      password:""
    }
  },
  created() {
  },
  mounted() {
    this.EmployeeId = this.$route.params.AdminId
    this.Username = this.$route.params.Username
    this.FirstlastName = this.$route.params.name
    this.Email = this.$route.params.email
    this.IsActive = this.$route.params.IsActive
    this.PmsId = this.$route.params.PmsId
  },
  methods: {
    Confirm_() {
      $(".bd-confirm-modal-xl").modal({backdrop: "static"});
      $('.bd-confirm-modal-xl').modal('show')
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    async EditEmployee() {
      if (this.Username == "" || this.FirstlastName == "" || this.Email == "") {
        alert("กรุณาใส่ข้อมูลให้ครบถ้วน")
        this.password = "";
        $('.bd-confirm-modal-xl').modal('hide')
        return;
      }

      let Obj = {
        token: localStorage.getItem("Token"),
        Password: this.password,
        request: {
          AdminId: this.EmployeeId+"",
          PmsId: this.PmsId+"",
          Name: this.FirstlastName,
          IsActive: this.IsActive+"",
          Email: this.Email,
          Password: "",
          create_by: localStorage.getItem("User"),
        },
      }

      this.axios.post(this.DomainBaseURL + "api/v1/employee/update_employee", Obj)
          .then((response) => {
            if (response.data.code == 0) {
              alert("แก้ไขสำเร็จ");
              $('.bd-confirm-modal-xl').modal('hide')
              location.href = '/employee';
              return;
            } else if (response.data.code == 1010) {
              alert("ไม่สามารถแก้ไข เนื่องจากยืนยันรหัสผ่านไม่ถูกต้อง");
              this.password = "";
              $('.bd-confirm-modal-xl').modal('hide')
              return;
            } else {
              alert("ไม่สามารถแก้ไข");
              this.password = "";
              $('.bd-confirm-modal-xl').modal('hide')
              return;
            }
          });
    },
  }
}
</script>

