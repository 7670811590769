<template>
  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>รายการผลหวยล็อคทั้งหมด</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <a href="/Dashboard">หน้าแรก</a>
              </li>
              <li class="breadcrumb-item active">รายการผลหวยล็อคทั้งหมด</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <!-- <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="row card-body">
                <div class="col-2 responsive-col-12">
                  <div class="row">
                    <div class="col">ค้นหาเลขที่ออก(เฉพาะ 6 หลัก)</div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <input
                        class="form-control form-control-sm"
                        maxlength="6"
                        type="number"
                        placeholder="ใส่เลขที่ออก"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        v-model="search_number_lock"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="col-10 responsive-col-12"
                  style="padding-top: 1.1rem !important"
                >
                  <div class="row">
                    <div class="col pr-0">
                      <button
                        class="btn btn btn-warning btn-rounded"
                        @click.prevent="Search()"
                      >
                        ค้นหา
                      </button>
                      &nbsp;
                      <button
                        class="btn btn bg-lotus btn-rounded"
                        @click.prevent="Inittoday()"
                      >
                        วันนี้
                      </button>
                      &nbsp;
                      <button
                        class="btn btn btn-secondary btn-rounded"
                        @click.prevent="Inityesterday()"
                      >
                        เมื่อวาน
                      </button>
                      &nbsp;
                      <button
                        class="btn btn bg-pink btn-rounded"
                        @click.prevent="Initweek()"
                      >
                        สัปดาห์
                      </button>
                      &nbsp;
                      <button
                        class="btn btn bg-purple btn-rounded"
                        @click.prevent="Initmonth()"
                      >
                        เดือน
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row card-body pt-0">
                <div class="col-2 responsive-col-12">
                  <div class="row">
                    <div class="col">เริ่มวันที่ - ถึงวันที่</div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <date-picker
                        v-model="date1"
                        lang="en"
                        type="date"
                        range
                        style="width: 100%"
                        format="YYYY-MM-DD"
                      >
                      </date-picker>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-sm-12">
            <div class="card card-primary card-tabs">
              <!-- pageAll -->
              <div
                id="custom-tabs-one-WithdrawSuccess1"
                class="tab-pane active"
              >
                <div class="card-body pt-0">
                  <br />
                  <div class="row">
                    <div class="col-10 text-left">
                      <h5><large class="text-danger">*</large>หวย{{Lotto.name_lotto}} สามารถกรอกผลได้ภายใน เวลา {{Lotto.close_time}} - {{Lotto.time_Announcement}}</h5>
                    </div>
                    <div class="col text-right">
                      <button
                        class="btn btn btn-primary btn-rounded mr-3 mb-2"
                        @click.prevent="Add_lotto()"
                      >
                        เพิ่มข้อมูล
                      </button>
                    </div>
                  </div>
                  <section class="content">
                    <div class="container-fluid">
                      <div class="row">
                        <div class="col-12 pl-0" style="overflow-x: auto">
                          <table
                            id="TableDepositFail"
                            class="
                              table table-striped table-bordered table-sm
                            "
                          >
                            <thead class="bg-header">
                              <tr>
                                <th
                                  class="
                                    text-center
                                    font-weight-normal
                                    py-3
                                    p-0
                                  "
                                  v-for="header in tableHeader"
                                  :key="header"
                                >
                                  {{ header }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(Website, index) in ListWebsite"
                                :key="index"
                                class="bg-white text-center"
                                v-if="(formatDate(Website.work_date) == current_date && current_time > Lotto.close_time && current_time < Lotto.time_Announcement)"
                              >
                                <td>{{ formatDate(Website.work_date) }}</td>
                                <td>{{ Website.numberset}}</td>
                                <td>{{ digit5(Website.numberset) }}</td>
                                <td>{{ digit4(Website.numberset) }}</td>
                                <td>{{ digit3(Website.numberset) }}</td>
                                <td>{{ digit2(Website.numberset) }}</td>
                                <td>{{ digit1(Website.numberset) }}</td>
                                <td>
                                  <button
                                    class="btn btn btn-warning btn-rounded"
                                    @click.prevent="update_lotto(Website)"
                                  >
                                    แก้ไข
                                  </button>
                                  &nbsp;
                                  <button
                                    class="btn btn btn-danger btn-rounded"
                                    @click.prevent="
                                      show_approve_remove(Website)
                                    "
                                  >
                                    ลบข้อมูล
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <!-- /.col -->
                      </div>
                      <!-- /.row -->
                    </div>
                    <!-- /.container-fluid -->
                  </section>
                </div>
                <!-- /.content -->
              </div>
              <!-- pageWaiting -->

              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- เพิ่มข้อมูลเว็บไซต์ -->
    <div
      class="modal fade add-lotto-lock-modal-xl"
      id="ConfirmModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              เพิ่มข้อมูลหวยล็อค
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <!-- Model -->
          <div class="modal-body">
            <form>
              <div class="form-group">
                <div class="row">
                  <div class="col">วันที่ออกผล</div>
                </div>
                <div class="row">
                  <div class="col">
                    <date-picker v-model="date_lock2" lang="en" style="width: 100%" value-type="format" format="YYYY-MM-DD" disabled></date-picker>
                  </div>
                </div>
              </div>
            </form>

            <form>
              <div class="form-group">
                <label for="message-text" class="col-form-label"
                  >เลขที่ออก(6 หลัก)</label
                >
                <input
                  type="number"
                  maxlength="6"
                  class="form-control"
                  placeholder="ใส่เลขที่ออก"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  v-model="Number_Lotto"
                />
                <label><large class="text-danger">*</large>หวย{{Lotto.name_lotto}} สามารถกรอกผลได้ภายใน เวลา {{Lotto.close_time}} - {{Lotto.time_Announcement}}</label>
              </div>
            </form>
            <form>
              <div class="form-group">
                <label for="message-text" class="col-form-label"
                  >ตัวอย่างของการออกผลรางวัล</label
                >
                <p for="message-text" class="col-form-label">รางวัล 6 หลัก : {{ Number_Lotto }}</p>
                <p for="message-text" class="col-form-label">รางวัล 5 หลัก : {{ digit5(Number_Lotto) }}</p>
                <p for="message-text" class="col-form-label">รางวัล 4 หลัก : {{ digit4(Number_Lotto) }}</p>
                <p for="message-text" class="col-form-label">รางวัล 3 หลัก : {{ digit3(Number_Lotto) }}</p>
                <p for="message-text" class="col-form-label">รางวัล 2 หลัก : {{ digit2(Number_Lotto) }}</p>
                <p for="message-text" class="col-form-label">รางวัล 1 หลัก : {{ digit1(Number_Lotto) }}</p>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-danger"
              data-dismiss="modal"
              @click.prevent="Clear_data()"
            >
              ยกเลิก
            </button>
            <button
              @click.prevent="Save_Result_Lotto()"
              type="button"
              class="btn btn-success"
            >
              บันทึก
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- เพิ่มข้อมูลเว็บไซต์ -->
    <!-- แก่้ไขข้อมูลเว็บไซต์ -->
    <div
      class="modal fade update-lotto-lock-modal-xl"
      id="ConfirmModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              แก้ไขข้อมูลหวยล็อค
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <!-- Model -->
          <div class="modal-body">
            <form>
              <div class="form-group">
                <div class="row">
                  <div class="col">วันที่ออกผล</div>
                </div>
                <div class="row">
                  <div class="col">
                    <date-picker
                      lang="en"
                      type="date"
                      style="width: 100%"
                      format="YYYY-MM-DD"
                      v-model="work_date"
                      value-type="format"
                      disabled
                    >
                    </date-picker>
                  </div>
                </div>
              </div>
            </form>

            <form>
              <div class="form-group">
                <label for="message-text" class="col-form-label"
                  >เลขที่ออก(6 หลัก)</label
                >
                <input
                  type="number"
                  maxlength="6"
                  class="form-control"
                  placeholder="ใส่เลขที่ออก"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  v-model="numberset"
                />
              </div>
            </form>
            <form>
              <div class="form-group">
                <label for="message-text" class="col-form-label text-danger"
                  >*สามารถแก้ไขได้จนถึงเวลาก่อนประกาศผลของวันนี้</label
                >
              </div>
            </form>
            <form>
              <div class="form-group">
                <label for="message-text" class="col-form-label"
                  >ตัวอย่างของการออกผลรางวัล</label
                >
                <p for="message-text" class="col-form-label">รางวัล 6 หลัก : {{ numberset }}</p>
                <p for="message-text" class="col-form-label">รางวัล 5 หลัก : {{ digit5(numberset) }}</p>
                <p for="message-text" class="col-form-label">รางวัล 4 หลัก : {{ digit4(numberset) }}</p>
                <p for="message-text" class="col-form-label">รางวัล 3 หลัก : {{ digit3(numberset) }}</p>
                <p for="message-text" class="col-form-label">รางวัล 2 หลัก : {{ digit2(numberset) }}</p>
                <p for="message-text" class="col-form-label">รางวัล 1 หลัก : {{ digit1(numberset) }}</p>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-danger"
              data-dismiss="modal"
              @click.prevent="Clear_data()"
            >
              ยกเลิก
            </button>
            <button
              @click="fix_lotto()"
              type="button"
              class="btn btn-success"
            >
              บันทึก
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- แก้ไขข้อมูลเว็บไซต์ -->
    <!-- Show approve remove -->
    <div
      class="modal fade approve-remove-modal-xl"
      id="approve-remove"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label
                  for="message-text"
                  class="col-form-label font-weight-bold"
                  >ต้องการลบข้อมูลหวยล็อค วันที่ {{ work_date }} ใช่หรือไม่</label
                >
              </div>
            </form>
          </div>
          <div class="modal-footer text-center">
            <button type="button" class="btn btn-danger" data-dismiss="modal">
              ย้อนกลับ
            </button>
            &nbsp;
            <button
              @click.prevent="delete_lotto()"
              type="button"
              class="btn btn-primary"
            >
              ตกลง
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Show approve remove-->
    <!-- /.content -->
    <!-- /.content -->
  </div>
  <!-- /.content-wrapper -->
</template>

<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/zh-cn";
import main from "../../components/Layout/LayoutMain";

export default {
  name: "AllLottoLock",
  components: {
    DatePicker,
  },
  data() {
    return {
      ListWebsite: [],
      tableHeader: [
        "วันที่",
        "รางวัลที่ 6",
        "รางวัลที่ 5",
        "รางวัลที่ 4",
        "รางวัลที่ 3",
        "รางวัลที่ 2",
        "รางวัลที่ 1",
        "จัดการ",
      ],
      Number_Lotto:null,
      search_number_lock:null,
      date1: [new Date(), new Date()],
      date_lock: '2019-10-09',
      date_lock2: moment(Date.now()).format("YYYY-MM-DD"),
      password:null,
      SelectLotto:null,
      work_date:null,
      numberset:"",
      number_id:null,
      status:null,
      Lotto:{},
      current_time:"",
      current_date:"",
      close_time:""

    };
  },
  created() {
    this.GetLotto();
  },
  mounted() {
    this.Initmonth();
  },

  methods: {
    digit5(value) {
      let int_to_string = value+'';
      return int_to_string.slice(1);
    },
    digit4(value) {
      let int_to_string = value+'';
      return int_to_string.slice(2);
    },
    digit3(value) {
      let int_to_string = value+'';
      return int_to_string.slice(3);
    },
    digit2(value) {
      let int_to_string = value+'';
      return int_to_string.slice(4);
    },
    digit1(value) {
      let int_to_string = value+'';
      return int_to_string.slice(5);
    },
    async GetLotto() {
      let Obj = {
        token: localStorage.getItem("Token"),
        request: {},
      };

      this.axios
        .post(this.DomainBaseURL + "api/v1/get/get_lotto", Obj)
        .then((response) => {
          this.Lotto = response.data.res[0];
        });
      
      this.current_date =  moment(Date.now()).format("YYYY-MM-DD");
      this.current_time =  moment(Date.now()).format("HH:mm:ss");
    },
    // disabledBeforeTodayAndAfterAWeek(date) {
    //   const today = new Date();
    //   today.setHours(0, 0, 0, 0);
    //   return date < today || date > new Date(today.getTime() + 14 * 24 * 3600 * 1000);
    // },
    num_format(inp, toFixed = 2) {
      try {
        toFixed = toFixed || 2;
        if (toFixed == 99) {
          toFixed = 0;
        }
        if (inp == "" || inp == undefined) {
          inp = "0";
        }
        inp += "";
        var rt = parseFloat(inp)
          .toFixed(toFixed)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      } catch (err) {
        var rt = "";
      }
      return rt;
    },
    delete_lotto() {
      const Obj = {
        token: localStorage.getItem("Token"),
        request : {
          set_number: this.numberset,
          number_id: this.number_id,
          status:(this.status == true? 1 : 0),
          dl:1,
          create_by: localStorage.getItem("User"),
        }
      };
      this.axios
        .post(
          this.DomainBaseURL + "api/v1/update/lotto_set_update",Obj
        )
        .then((response) => {
          if(response.data.code == 0){
            this.InitLottoAll();
            this.Clear_data();
          }
        });
    },
    fix_lotto() {
      const Obj = {
        token: localStorage.getItem("Token"),
        request : {
          set_number: this.numberset,
          number_id: this.number_id,
          date:this.SelectLotto.work_date,
          status:(this.status == true? 1 : 0),
          dl:0,
          create_by: localStorage.getItem("User"),
        }
      };
      this.axios
        .post(
          this.DomainBaseURL + "api/v1/update/lotto_set_update",Obj
        )
        .then((response) => {
          if(response.data.code == 0){
            this.InitLottoAll();
            this.Clear_data();
          }
        });
    },
    Clear_data() {
      this.Number_Lotto = ""
      this.date_lock = null
      this.number_id = null
      this.set_number = ""
      this.status = null
      $(".add-lotto-lock-modal-xl").modal("hide");
      $(".update-lotto-lock-modal-xl").modal("hide");
      $(".approve-remove-modal-xl").modal("hide");
    },
    Add_lotto() {
      this.Clear_data();
      $(".add-lotto-lock-modal-xl").modal({ backdrop: "static" });
      $(".add-lotto-lock-modal-xl").modal("show");
    },
    show_approve_remove(lotto) {
      this.SelectLotto = lotto;
      this.numberset = this.SelectLotto.numberset;
      this.work_date = this.formatDate(this.SelectLotto.work_date);
      this.number_id = this.SelectLotto.id_lotto;
      this.status = this.SelectLotto.active;
      $(".approve-remove-modal-xl").modal({ backdrop: "static" });
      $(".approve-remove-modal-xl").modal("show");
    },
    update_lotto(lotto) {
      this.SelectLotto = lotto;
      this.numberset = this.SelectLotto.numberset;
      this.work_date = this.formatDate(this.SelectLotto.work_date);
      this.number_id = this.SelectLotto.id_lotto;
      $(".update-lotto-lock-modal-xl").modal({ backdrop: "static" });
      $(".update-lotto-lock-modal-xl").modal("show");
    },
    Save_Result_Lotto() {
      if(this.Number_Lotto.length != 6){
        alert("กรุณาใส่ตัวเลขให้ถูกต้อง")
        return;
      }
      if(this.date_lock2 == this.current_date && this.current_time > this.Lotto.time_Announcement){
        alert("ไม่สามารถสร้างรายการได้ เนื่องจากเลยเวลาประกาศผลหวย "+this.Lotto.time_Announcement+" ของวันนี้แล้ว")
        return;
      }
      if(this.date_lock2 == this.current_date && this.current_time < this.Lotto.close_time){
        alert("ไม่สามารถสร้างรายการได้ เนื่องจากยังไม่ถึงเวลาปิดหวย และสามารถสร้างรายการได้ในเวลา "+this.Lotto.close_time+' ถึง '+this.Lotto.time_Announcement+' ของวันนี้')
        return;
      }
      let Obj = {
        token: localStorage.getItem("Token"),
        request : {
          set_number: this.Number_Lotto,
          work_set: this.date_lock2,
          create_by: localStorage.getItem("User"),
        }
      };

      if(this.date_lock2 == this.current_date && this.current_time > this.Lotto.close_time && this.current_time < this.Lotto.time_Announcement) {
        this.axios
          .post(this.DomainBaseURL + "api/v1/insert/lotto_set_insert", Obj)
          .then((response) => {
            if (response.data.code == 1000) {
              alert("วันที่ที่ท่านเลือก ถูกล็อคผลไว้เรียบร้อยแล้ว หากต้องการเปลี่ยนแปลงข้อมูล กรุณากดแก้ไข")
              return;
            }
            else if(response.data.code == 0){
              this.Clear_data();
              this.InitLottoAll();
            }
          });
      }
    },
    Search() {
      this.InitLottoAll()
    },
    Inittoday() {
      this.date1 = [new Date(moment(Date.now() - 0 * 24 * 3600 * 1000)),new Date(moment(Date.now() - 0 * 24 * 3600 * 1000))]
      this.InitLottoAll()
    },
    Inityesterday() {
      this.date1 = [new Date(moment(Date.now() - 1 * 24 * 3600 * 1000).format("YYYY-MM-DD")),new Date(moment(Date.now() - 1 * 24 * 3600 * 1000).format("YYYY-MM-DD"))]
      this.InitLottoAll()
    },
    Initweek() {
      this.date1 = [new Date(moment().weekday(1).format("YYYY-MM-DD")),new Date(moment().weekday(7).format("YYYY-MM-DD"))]
      this.InitLottoAll()
    },
    Initmonth() {
      this.date1 = [new Date(moment().date(1).format("YYYY-MM-DD")),new Date(moment().date(31).format("YYYY-MM-DD"))]
      this.InitLottoAll()
    },
    InitLottoAll() {
      let Obj = {
        token: localStorage.getItem("Token"),
        request:{
          numberset:this.search_number_lock,
          start_date:(this.formatDate(this.date1[0]) == "Invalid date"? "" : this.formatDate(this.date1[0])),
          end_date:(this.formatDate(this.date1[1]) == "Invalid date"? "" : this.formatDate(this.date1[1]))
        }
      };
      this.axios
        .post(this.DomainBaseURL + "api/v1/get/get_lotto_all", Obj)
        .then((response) => {
          this.ListWebsite = response.data.res;
        });
    },
    formatDate(value) {
      return moment(String(value)).format("YYYY-MM-DD");
    },
    formateDateTime(value) {
      return moment(String(value)).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  filters: {
    formateDateTime(value) {
      return moment(String(value)).format('YYYY-MM-DD HH:mm:ss')
    },
    get_date(date) {
    return moment(date).format('YYYY-MM-DD');
    },
    get_time(date) {
    return moment(date).format('HH:mm:ss');
    },
  },
};
</script>

<style>
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.bg-header {
  background-color: #353a40;
  color: white;
}
.bg-before-credit {
  background-color: #06bad5;
}
.bg-after-credit {
  background-color: #f7685b;
}
.bg-scb {
  background-color: #562684;
}
.bg-kbank {
  background-color: #2f963b;
}
.ml-3-custom {
  margin-left: 1rem;
}
.table th,
.table td {
  vertical-align: middle !important;
}
</style>
