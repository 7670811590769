<template>
<div class="hold-transition login-page">
  <div class="login-box">
    <div class="login-logo">
      <a href="../../index2.html"><b>Backoffice</b></a>
    </div>
    <!-- /.login-logo -->
    <div class="card">
      <div class="card-body login-card-body">
        <form v-on:submit.prevent="CheckLogin">
          <div class="input-group mb-3">
            <input type="text" class="form-control" v-model="form.Username" placeholder="Username">
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-envelope"></span>
              </div>
            </div>
            <div class="col-12">
              <p class="warring-valid" v-if="!$v.form.Username.required && $v.form.Username.$error">
                กรุณากรอกยูสเซอร์
              </p>
           </div>
          </div>
          <div class="input-group mb-3">
            <input type="password" class="form-control" v-model="form.Password" placeholder="Password">
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-lock"></span>
              </div>
            </div>
            <div class="col-12">
              <p class="warring-valid" v-if="!$v.form.Password.required && $v.form.Password.$error">
                กรุณากรอกรหัสผ่าน
              </p>
           </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button type="submit" class="btn btn-primary btn-block">เข้าสู่ระบบ</button>
               <p class="warring-valid" v-if="LoginFail">
                ยูสเซอร์หรือรหัสผ่านไม่ถูกต้อง
              </p>
            </div>
            <!-- /.col -->
          </div>
        </form>
      </div>
      <!-- /.login-card-body -->
    </div>
  </div>
  <!-- /.login-box -->
</div>
</template>

<script>
import { required, email, minLength } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      form : {
        Username : "",
        Password : "",
      },
       LoginFail : false
    }
  },
  validations: {
    form: {
      Username: { required },
      Password: { required },
    }
  },
  methods : {
    async CheckLogin() {
      this.$v.$touch();
      if(!this.$v.$invalid) {
        let Obj = {
          username : this.form.Username,
          password : this.form.Password
        }
        let result =  await this.axios.post(this.DomainBaseURL+'api/v1/Auth/CheckLogin_admin', Obj)
        if(result.data.code == 0){
          localStorage.setItem("Token", result.data.res.Token)
          window.location = '/all-lotto-lock'
        } else {
          this.LoginFail = true
          console.log("Login Fail")
        }
      }
    }
  }
}


</script>

<style>
.warring-valid {
  color: red;
  display: inline-block;
}
</style>