<template>
  <div class="container">
    <div class="form-row">
      <div class="col-12" style="padding: 30px">
        <h1>ตั้งค่าหวย</h1>
      </div>
    </div>
    <div class="form-group">
      <div class="form-group row">
        <div class="col-lg-6 col-12 text-right">
          <label class="col-sm-12 col-lg-6 col-form-label col-form-label-lg"
            >ชื่อหวย :</label
          >
        </div>
        <div class="col-sm-12 col-lg-4 text-right">
          <input
            class="form-control"
            type="text"
            v-model="Lotto.name_lotto"
          />
        </div>
      </div>
      <div class="form-group row">
        <div class="col-lg-6 col-12 text-right">
          <label class="col-sm-12 col-lg-6 col-form-label col-form-label-lg"
            >ประกาศหวย :</label
          >
        </div>
        <div class="col-sm-12 col-lg-4 text-right">
          <date-picker
            v-model="Lotto.time_Announcement"
            value-type="format"
            lang="en"
            type="time"
            style="width: 100%"
            format="HH:mm:ss"
          >
          </date-picker>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-lg-6 col-12 text-right">
          <label class="col-sm-12 col-lg-6 col-form-label col-form-label-lg"
            >ปิดหวย/เปิดการแก้ไข :</label
          >
        </div>
        <div class="col-sm-12 col-lg-4 text-right">
          <date-picker
            v-model="Lotto.close_time"
            value-type="format"
            type="time"
            lang="en"
            style="width: 100%"
            format="HH:mm:ss"
          >
          </date-picker>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 col-lg-6 ">
          <button
            type="submit"
            @click.prevent="UpdateLotto()"
            class="btn btn-primary w-100"
          >
            บันทึก
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/zh-cn";

export default {
  name: "Setting",
  components: {
    DatePicker,
  },
  data() {
    return {
      name_lotto: "",
      Lotto:{},
    };
  },
  mounted() {
    this.GetLotto()
  },
  methods: {
    async GetLotto() {
      let Obj = {
        token: localStorage.getItem("Token"),
        request: {},
      };

      this.axios
        .post(this.DomainBaseURL + "api/v1/get/get_lotto", Obj)
        .then((response) => {
          this.Lotto = response.data.res[0];
        });
    },
    UpdateLotto() {
      if (this.Lotto.close_time >  this.Lotto.time_Announcement) {
        alert("แก้ไขไม่สำเร็จ เนื่องจากเวลาปิดการแก้ไขหวย มากกว่าเวลาประกาศหวยไม่ได้ กรุณาทำรายการใหม่อีกครั้ง")
        return;
      }
      let Obj = {
        token: localStorage.getItem("Token"),
        request: {
          name_lotto:this.Lotto.name_lotto,
          time_close: this.Lotto.close_time,
          Announcement: this.Lotto.time_Announcement,
          dl: 0,
          status: 1,
          id: 1,
          create_by: localStorage.getItem("User"),
        },
      };

      this.axios
        .post(this.DomainBaseURL + "api/v1/update/lotto_update", Obj)
        .then((response) => {
          if (response.data.code == 0) {
            alert("แก้ไขข้อมูลสำเร็จ")
            this.GetLotto()
          }
        });
    },
  },
  filters: {
    formatDate(value) {
      return moment(String(value)).format("YYYY-MM-DD HH:mm:ss");
    },
  },
};
</script>

<style></style>
