<template>
  <div class="container">
    <div class="form-row">
      <div class="col-12" style="padding: 30px">
        <h1>เปลี่ยนรหัสผ่าน</h1>
      </div>
    </div>
    <div class="form-group">
      <div class="form-group row">
        <div class="col-4 text-right">
          <label class="col-sm-6 col-form-label col-form-label-lg"
          >ชื่อผู้ใช้งาน : </label>
        </div>
        <div class="col-4" style="text-align:start">
          <label class="form-control form-control-lg">{{ Profile.Username }}</label>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-4 text-right">
          <label class="col-sm-6 col-form-label col-form-label-lg"
          >รหัสผ่านเก่า :</label
          >
        </div>
        <div class="col-4 text-left">
          <input
              class="form-control form-control-lg s_password n_password"
              type="password"
              placeholder="กรอกรหัสผ่านเก่า"
              v-model="old_password"
              maxlength="20"

          />
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-4 text-right">
          <label class="col-sm-6 col-form-label col-form-label-lg"
          >รหัสผ่านใหม่ :</label
          >
        </div>
        <div class="col-4 text-left">
          <input
              class="form-control form-control-lg s_password n_password"
              type="password"
              placeholder="กรอกรหัสผ่านใหม่"
              v-model="new_password"
              maxlength="20"

          />
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-4 text-right">
          <label class="col-sm-6 col-form-label col-form-label-lg"
          >ยืนยันรหัสผ่าน :</label
          >
        </div>
        <div class="col-4 text-left">
          <input
              class="form-control form-control-lg s_password n_password"
              type="password"
              placeholder="กรอกรหัสผ่านใหม่"
              v-model="confirm_password"
              maxlength="20"

          />
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-4">
          <button type="submit" @click.prevent="EditEmployee()" class="btn btn-primary w-100">บันทึก</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "MemberEdit",
  data() {
    return {
      Token: localStorage.getItem("Token"),
      Profile: [],
      new_password:"",
      old_password:"",
      confirm_password:"",
    }
  },
  created() {
    this.GetProfile()
  },
  methods: {
    async GetProfile() {
      let Obj = {
        token: localStorage.getItem("Token"),
      };
      this.axios
        .post(this.DomainBaseURL + "api/v1/employee/checktoken", Obj)
        .then((response) => {
          this.Profile = response.data.res;
        });
    },
    async EditEmployee() {
      if (this.old_password == "" || this.new_password == "" || this.confirm_password == "") {
        alert("กรุณาใส่ข้อมูลให้ครบถ้วน")
        return;
      }

      if (this.confirm_password != this.new_password) {
        alert("ยืนยันรหัสผ่านไม่ตรงกัน")
        return;
      }

      let Obj = {
        token: localStorage.getItem("Token"),
        Password: this.old_password,
        request: {
          AdminId: this.Profile.AdminId+"",
          PmsId: this.Profile.PmsId+"",
          Name: this.Profile.name,
          IsActive: this.Profile.IsActive+"",
          Email: this.Profile.email,
          Password: this.new_password,
          create_by: localStorage.getItem("User"),
        }
      }

      this.axios.post(this.DomainBaseURL + "api/v1/employee/update_employee", Obj)
          .then((response) => {
            if (response.data.code == 0) {
              alert("แก้ไขสำเร็จ");
              location.href = '/changepassword';
              return;
            } else if (response.data.code == 1010) {
              alert("ไม่สามารถแก้ไข เนื่องจากรหัสผ่านเก่าไม่ถูกต้อง");
              return;
            } else {
              alert("ไม่สามารถแก้ไข");
              return;
            }
          });
    },
  },
  filters: {
    formatDate(value) {
      return moment(String(value)).format('YYYY-MM-DD HH:mm:ss')
    },
  }
}

</script>

<style></style>
